<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6" lg="4">
        <v-textarea v-model="local.camera" label="Camera" :disabled="!local.driverless" />
      </v-col>
      <v-col cols="6" lg="4">
        <v-textarea v-model="local.lidar" label="LiDAR" :disabled="!local.driverless" />
      </v-col>
      <v-col cols="6" lg="4">
        <v-textarea v-model="local.motionController" label="Motion Controller" :disabled="!local.driverless" />
      </v-col>
      <v-col cols="6" lg="4">
        <v-textarea v-model="local.motionPlanning" label="Motion Planning" :disabled="!local.driverless" />
      </v-col>
      <v-col cols="6" lg="4">
        <v-textarea v-model="local.slam" label="SLAM" :disabled="!local.driverless" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'TestingRunDriverlessForm',
  model: {
    prop: 'local',
    event: 'input'
  },
  props: { local: Object },
  data: () => ({ valid: true }),
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
