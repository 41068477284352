<template>
  <v-dialog :value="show" max-width="500" persistent>
    <v-card>
      <v-card-title> Danger Zone! </v-card-title>

      <v-card-text><slot /></v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> No </v-btn>
        <v-btn text color="error" @click="$emit('apply')"> Yes, apply! </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'AppApplyDialog',
  props: { show: { type: Boolean, required: true } }
}
</script>
