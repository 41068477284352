<template>
  <v-dialog v-model="isOpen" :fullscreen="fullscreen">
    <template v-slot:activator="{ on, attrs }">
      <v-btn tile block color="accent" v-bind="attrs" v-on="on"> Shim Calculator </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary"> Shim Calculator </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="4">
            <div class="text-h6">Current state</div>
            <setup-wheel-form v-model="current" unit="°" />
            <v-divider v-if="fullscreen"></v-divider>
          </v-col>

          <v-divider v-if="!fullscreen" vertical></v-divider>

          <v-col cols="12" lg="4">
            <div class="text-h6">Target state</div>
            <setup-axis-form v-model="target" unit="°" />
            <v-divider v-if="fullscreen"></v-divider>
          </v-col>

          <v-divider v-if="!fullscreen" vertical></v-divider>

          <v-col cols="12" lg="4">
            <div class="text-h6">Shims</div>
            <setup-wheel-info :config="shims" unit="mm" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="copyCurrent"> Copy current </v-btn>
        <v-btn color="secondary" text @click="copyTargets"> Copy targets </v-btn>
        <v-btn color="primary" text @click="isOpen = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SetupWheelForm from '@components/setup/WheelForm.vue'
import SetupWheelInfo from '@components/setup/WheelInfo.vue'
import SetupAxisForm from '@components/setup/AxisForm.vue'

function calcShims(c, t, f) {
  /**
   * c: current
   * t: target
   * f: factor
   */
  const value = (t - c) * f || 0
  const parsedValue = value.toLocaleString('en-GB', { maximumFractionDigits: 2 })
  return value === 0 ? value : value > 0 ? `Add ${parsedValue}` : `Remove ${parsedValue * -1}`
}

/**
 * parses all values of an object to float
 */
function objectParseFloat(o) {
  return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, parseFloat(v)]))
}

export default {
  name: 'SetupConfigCamberHelper',
  components: { SetupWheelForm, SetupWheelInfo, SetupAxisForm },
  props: { frontShimFactor: Number, rearShimFactor: Number },
  data: () => ({
    current: {
      frontLeft: undefined,
      frontRight: undefined,
      rearLeft: undefined,
      rearRight: undefined
    },
    target: {
      front: 0,
      rear: 0
    },
    isOpen: false
  }),
  methods: {
    copyCurrent() {
      const current = objectParseFloat(this.current)
      this.$emit('copy', {
        frontLeft: current.frontLeft.toFixed(2),
        frontRight: current.frontRight.toFixed(2),
        rearLeft: current.rearLeft.toFixed(2),
        rearRight: current.rearRight.toFixed(2)
      })
      this.isOpen = false
    },
    copyTargets() {
      const target = objectParseFloat(this.target)
      this.$emit('copy', {
        frontLeft: target.front.toFixed(2),
        frontRight: target.front.toFixed(2),
        rearLeft: target.rear.toFixed(2),
        rearRight: target.rear.toFixed(2)
      })
      this.isOpen = false
    },
    open() {
      this.isOpen = true
    }
  },
  computed: {
    shims() {
      const front_factor = this.frontShimFactor
      const rear_factor = this.rearShimFactor
      const current = objectParseFloat(this.current)
      const target = objectParseFloat(this.target)
      return {
        frontLeft: calcShims(current.frontLeft, target.front, front_factor),
        frontRight: calcShims(current.frontRight, target.front, front_factor),
        rearLeft: calcShims(current.rearLeft, target.rear, rear_factor),
        rearRight: calcShims(current.rearRight, target.rear, rear_factor)
      }
    },
    fullscreen() {
      return ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)
    }
  }
}
</script>
