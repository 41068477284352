<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="runLength"
          :rules="$rules.number"
          hint="Seperate with dot! E.g. '1234.23'"
          label="Run Length"
          suffix="m"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="trackLength"
          :rules="$rules.number"
          hint="Seperate with dot! E.g. '1234.23'"
          label="Track Length"
          suffix="m"
        ></v-text-field
      ></v-col>
      <v-col cols="12" sm="4">
        <v-alert text type="info">Number of Laps: {{ numLaps }}</v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TestingRunDataForm',
  data: () => ({
    runLength: 22000,
    trackLength: undefined
  }),
  computed: {
    numLaps() {
      if (!this.runLength || !this.trackLength) return '-'
      const value = this.runLength / this.trackLength
      return value.toLocaleString('en-GB', { maximumFractionDigits: 2 })
    }
  }
}
</script>
