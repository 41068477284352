<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="local.hvSocStart"
          label="HV Start SOC"
          hint="Seperate with dot! E.g. '1234.23'"
          suffix="%"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="local.hvSocEnd" label="HV End SOC" hint="Seperate with dot! E.g. '1234.23'" suffix="%" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="local.lvSocStart"
          label="LV Start SOC"
          hint="Seperate with dot! E.g. '1234.23'"
          suffix="%"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field v-model="local.lvSocEnd" label="LV End SOC" hint="Seperate with dot! E.g. '1234.23'" suffix="%" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'TestingRunSocForm',
  model: {
    prop: 'local',
    event: 'input'
  },
  props: { local: Object },
  data: () => ({ valid: true }),
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
