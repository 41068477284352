<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" md="6">
        <v-card :color="testingDay.baseSetup ? 'info' : 'warning'" outlined height="100%" :to="setupTo">
          <v-card-text v-if="testingDay.baseSetup">
            <p class="text-overline">Copied from Base Setup</p>
            <v-icon dense>mdi-wrench</v-icon> {{ testingDay.baseSetup.title }}
          </v-card-text>
          <v-card-text v-else>
            <p class="text-overline">Copied from Base Setup</p>
            <v-icon dense>mdi-wrench</v-icon> Base Setup doesn't exist (anymore)!
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text>
            <v-select
              v-model="copyfrom"
              :items="copysetups"
              label="Copy from Setup"
              item-text="name"
              item-value="id"
              :disabled="!copysetups.length"
              required
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Ride Height</p></v-card-text>
          <setup-wheel-form
            ref="rideHeightForm"
            v-model="local.setup.rideHeightConfig"
            unit="mm"
            @validate="valid = $event"
          />
          <v-alert v-model="rideHeightCopyAlert" color="green" type="success" dense
            >Copied front and rear targets!</v-alert
          >
          <setup-ride-height-helper :shimFactor="testingDay.car.shimFactors.rideHeight" @copy="setRideHeightConfig" />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Weight</p></v-card-text>
          <setup-wheel-form ref="weightForm" v-model="local.setup.weightConfig" unit="kg" @validate="valid = $event" />
          <v-alert v-model="weightCopyAlert" color="green" type="success" dense>Copied front and rear weights!</v-alert>
          <setup-weight-helper :shimFactor="testingDay.car.shimFactors.weight" @copy="setWeightConfig" />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text> <p class="text-overline">Toe</p></v-card-text>
          <setup-wheel-form ref="toeForm" v-model="local.setup.toeConfig" unit="°(deg)" @validate="valid = $event" />
          <v-alert v-model="toeCopyAlert" color="green" type="success" dense>Copied front and rear targets!</v-alert>
          <setup-toe-helper
            :frontShimFactor="testingDay.car.shimFactors.frontToe"
            :rearShimFactor="testingDay.car.shimFactors.rearToe"
            :wheelbase="testingDay.car.wheelbase"
            @copy="setToeConfig"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Camber</p></v-card-text>
          <setup-wheel-form
            ref="camberForm"
            v-model="local.setup.camberConfig"
            unit="°(deg)"
            @validate="valid = $event"
          />
          <v-alert v-model="camberCopyAlert" color="green" type="success" dense>Copied front and rear targets!</v-alert>
          <setup-camber-helper
            :frontShimFactor="testingDay.car.shimFactors.frontCamber"
            :rearShimFactor="testingDay.car.shimFactors.rearCamber"
            @copy="setCamberConfig"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Spring</p></v-card-text>
          <setup-config-spring-form
            ref="springForm"
            v-model="local.setup.springConfig"
            unit="N/mm"
            @validate="valid = $event"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Damper</p></v-card-text>
          <setup-config-damper-form
            ref="damperForm"
            v-model="local.setup.damperConfig"
            unit="clicks"
            @validate="valid = $event"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Target Tyre-Pressure</p></v-card-text>
          <setup-axis-form
            ref="targetTyreForm"
            v-model="local.setup.targetTyrePressure"
            unit="bar"
            @validate="valid = $event"
          />
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Tyres</p></v-card-text>

          <v-form ref="tyresForm" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    v-model="local.setup.tyres"
                    :items="[
                      { v: 'SLICK', t: 'Slick' },
                      { v: 'RAIN', t: 'Rain' }
                    ]"
                    item-text="t"
                    item-value="v"
                    label="Tyre Type"
                    required
                    :rules="$rules.required"
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="local.setup.tyreSet" :rules="$rules.required" label="Tyre Set" required />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field v-model="local.setup.tyreAge" :rules="$rules.number" label="Tyre Age" suffix="km" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <v-card outlined height="100%">
          <v-card-text><p class="text-overline">Driverless Components</p></v-card-text>

          <v-form ref="driverlessForm" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-switch v-model="local.setup.dvMounted" label="Driverless Mounted?" />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card>
      </v-col>
      <!-- End of Form -->
    </v-row>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'

import { CopyableSetupsWithData } from '@graphql/Setups.gql'

import SetupWheelForm from '@components/setup/WheelForm.vue'
import SetupAxisForm from '@components/setup/AxisForm.vue'
import SetupConfigSpringForm from '@/components/setup/ConfigSpringForm.vue'
import SetupConfigDamperForm from '@/components/setup/ConfigDamperForm.vue'
import SetupRideHeightHelper from '@components/setup/ConfigRideHeightHelper.vue'
import SetupWeightHelper from '@components/setup/ConfigWeightHelper.vue'
import SetupToeHelper from '@components/setup/ConfigToeHelper.vue'
import SetupCamberHelper from '@components/setup/ConfigCamberHelper.vue'

export default {
  name: 'TestingRunSetupForm',
  components: {
    SetupWheelForm,
    SetupAxisForm,
    SetupConfigSpringForm,
    SetupConfigDamperForm,
    SetupRideHeightHelper,
    SetupWeightHelper,
    SetupToeHelper,
    SetupCamberHelper
  },
  apollo: {
    copysetups: {
      query: CopyableSetupsWithData,
      update: (data) => {
        const runsetups = data.testingRuns.map((run) => ({
          id: run.setup.id,
          name: `Run ${run.number} from ${run.day.title} (${DateTime.fromISO(run.day.date).toFormat('dd LLL yyyy')})`,
          date: run.day.date,
          data: run.setup
        }))
        const basesetups = data.baseSetups.map((base) => ({
          id: base.setup.id,
          name: `Base Setup ${base.title} (${DateTime.fromISO(base.createdAt).toFormat('dd LLL yyyy')})`,
          date: base.createdAt,
          data: base.setup
        }))
        const copysetups = runsetups.concat(basesetups)
        copysetups.sort((a, b) => new Date(b.date) - new Date(a.date))
        return copysetups
      }
    }
  },
  model: {
    prop: 'local',
    event: 'input'
  },
  props: { local: Object, testingDay: Object },
  data: () => ({
    valid: false,
    rideHeightCopyAlert: false,
    weightCopyAlert: false,
    toeCopyAlert: false,
    camberCopyAlert: false,
    copysetups: [],
    copyfrom: ''
  }),
  computed: {
    setupTo() {
      if (!this.testingDay.baseSetup) return { name: 'Setups' }
      return { name: 'Setup', params: { id: this.testingDay.baseSetup.id } }
    }
  },
  created() {
    if (!this.local.setup.rideHeightConfig) this.local.setup.rideHeightConfig = {}
    if (!this.local.setup.weightConfig) this.local.setup.weightConfig = {}
    if (!this.local.setup.toeConfig) this.local.setup.toeConfig = {}
    if (!this.local.setup.camberConfig) this.local.setup.camberConfig = {}
    if (!this.local.setup.springConfig) this.local.setup.springConfig = {}
    if (!this.local.setup.damperConfig) this.local.setup.damperConfig = {}
    if (!this.local.setup.targetTyrePressure) this.local.setup.targetTyrePressure = {}
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    },
    setRideHeightConfig(conf) {
      this.local.setup.rideHeightConfig = { ...conf }
      this.rideHeightCopyAlert = true
      setTimeout(
        function () {
          this.rideHeightCopyAlert = false
        }.bind(this),
        3000
      )
    },
    setWeightConfig(conf) {
      this.local.setup.weightConfig = { ...conf }
      this.weightCopyAlert = true
      setTimeout(
        function () {
          this.weightCopyAlert = false
        }.bind(this),
        3000
      )
    },
    setToeConfig(conf) {
      this.local.setup.toeConfig = { ...conf }
      this.toeCopyAlert = true
      setTimeout(
        function () {
          this.toeCopyAlert = false
        }.bind(this),
        3000
      )
    },
    setCamberConfig(conf) {
      this.local.setup.camberConfig = { ...conf }
      this.camberCopyAlert = true
      setTimeout(
        function () {
          this.camberCopyAlert = false
        }.bind(this),
        3000
      )
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    },
    copyfrom() {
      const copysetup = this.copysetups.find((setup) => setup.id === this.copyfrom).data

      if (copysetup.rideHeightConfig) this.local.setup.rideHeightConfig = copysetup.rideHeightConfig
      if (copysetup.weightConfig) this.local.setup.weightConfig = copysetup.weightConfig
      if (copysetup.toeConfig) this.local.setup.toeConfig = copysetup.toeConfig
      if (copysetup.camberConfig) this.local.setup.camberConfig = copysetup.camberConfig
      if (copysetup.springConfig) this.local.setup.springConfig = copysetup.springConfig
      if (copysetup.damperConfig) this.local.setup.damperConfig = copysetup.damperConfig
      if (copysetup.targetTyrePressure) this.local.setup.targetTyrePressure = copysetup.targetTyrePressure
      if (copysetup.tyres) this.local.setup.tyres = copysetup.tyres
      if (copysetup.tyreAge) this.local.setup.tyreAge = copysetup.tyreAge
      if (copysetup.tyreSet) this.local.setup.tyreSet = copysetup.tyreSet
    }
  }
}
</script>
