<template>
  <v-card elevation="6" outlined>
    <v-card-title class="text-h5" v-text="testingDay.title"></v-card-title>
    <v-card-subtitle>{{ day }}</v-card-subtitle>

    <v-card-text>
      <div class="text-overline">Responsible</div>
      <v-chip>
        <v-avatar left> <v-img :src="testingDay.responsible.googlePicture" /> </v-avatar>
        {{ testingDay.responsible.fullname }}
      </v-chip>

      <div class="text-overline">Car</div>
      <v-chip>
        <v-avatar left> <v-icon dense>mdi-car</v-icon> </v-avatar>
        {{ testingDay.car.name }}
      </v-chip>

      <div class="text-overline">Ground</div>
      <v-chip>
        <v-avatar left> <v-icon dense>mdi-go-kart-track</v-icon> </v-avatar>
        {{ groundCasing }}
      </v-chip>

      <div class="text-overline">Setup</div>
      <v-card outlined height="100%" :to="setupTo">
        <v-card-text>
          <div v-if="testingDay.baseSetup"><v-icon dense>mdi-wrench</v-icon> {{ testingDay.baseSetup.title }}</div>
          <div v-else>
            <v-alert type="error"
              >Setup doesn't exist (anymore)! Please select a valid one! Runs can't be created without a valid
              Setup!</v-alert
            >
          </div>
        </v-card-text>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <slot />
    </v-card-actions>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'TestingDayInfoCard',
  props: { testingDay: Object },
  computed: {
    setupTo() {
      if (!this.testingDay.baseSetup) return { name: 'Setups' }
      return { name: 'Setup', params: { id: this.testingDay.baseSetup.id } }
    },
    day() {
      return DateTime.fromISO(this.$route.params.date).toFormat('dd MMMM yyyy')
    },
    groundCasing() {
      return this.testingDay.ground.charAt(0).toUpperCase() + this.testingDay.ground.slice(1).toLowerCase()
    }
  }
}
</script>
