<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" lg="4">
        <p class="text-overline blue--text text--lighten-3">Cold Tyre-Pressure</p>
        <p class="text-subtitle-2">Measure before the run</p>
        <setup-wheel-form v-model="local.coldTyrePressure" unit="bar" @validate="valid = $event" />
      </v-col>
      <v-divider v-if="dividerIsVertical" vertical></v-divider>

      <v-col cols="12" lg="4">
        <v-divider v-if="!dividerIsVertical"></v-divider>
        <p class="text-overline amber--text text--lighten-3">Warmup Tyre-Pressure</p>
        <p class="text-subtitle-2">Measure after the warmup lap</p>
        <setup-wheel-form v-model="local.warmupTyrePressure" unit="bar" @validate="valid = $event" />
      </v-col>
      <v-divider v-if="dividerIsVertical" vertical></v-divider>

      <v-col cols="12" lg="4">
        <v-divider v-if="!dividerIsVertical"></v-divider>
        <p class="text-overline deep-orange--text text--lighten-3 mb-0">Warm Tyre-Pressure</p>
        <p class="text-subtitle-2">Measure directly after the run</p>
        <setup-wheel-form v-model="local.warmTyrePressure" unit="bar" @validate="valid = $event" />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import SetupWheelForm from '@components/setup/WheelForm.vue'

export default {
  name: 'TestingRunTyreForm',
  components: { SetupWheelForm },
  model: {
    prop: 'local',
    event: 'input'
  },
  props: { local: Object },
  data: () => ({
    valid: false
  }),
  computed: {
    dividerIsVertical() {
      return !['xs', 'sm', 'md'].some((e) => e === this.$vuetify.breakpoint.name)
    }
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
