<template>
  <v-row justify="center">
    <v-col cols="6" class="text-right">
      <div class="text-caption">Rake</div>
      <div class="text-subtitle-1">{{ rake.rake.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} mm</div>
    </v-col>
    <v-col cols="6" class="text-left">
      <div class="text-caption">Relative Rake</div>
      <div class="text-subtitle-1">{{ rake.relative.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} %</div>
    </v-col>
  </v-row>
</template>

<script>
function calculateRake(wb, fl, fr, rl, rr) {
  /**
   * uses ride-heights
   * wb: wheelbase -> defined in "car"
   * ff: frontLeft
   * fr: frontRight
   * rl: rearLeft
   * rr: rearRight
   */
  wb = parseFloat(wb)
  fl = parseFloat(fl)
  fr = parseFloat(fr)
  rl = parseFloat(rl)
  rr = parseFloat(rr)
  const only_numeric = [wb, fl, fr, rl, rr].every((v) => !isNaN(v))
  if (!only_numeric) return { rake: 0, relative: 0 }

  const rake = (rl + rr) / 2 - (fl + fr) / 2 || 0
  const relative = (Math.atan(rake / wb) * 180) / Math.PI || 0

  return { rake, relative }
}

export default {
  name: 'SetupRake',
  props: { config: Object, axes: Object },
  computed: {
    rake() {
      const wheelbase = 1530 // from richters-cek
      if (this.config) {
        return calculateRake(
          wheelbase,
          this.config.frontLeft,
          this.config.frontRight,
          this.config.rearLeft,
          this.config.rearRight
        )
      } else {
        return calculateRake(wheelbase, this.axes.front, this.axes.front, this.axes.rear, this.axes.rear)
      }
    }
  }
}
</script>
