<template>
  <v-row justify="center" class="text-left">
    <v-col cols="6" sm="3">
      <div class="text-caption">Overall Weight</div>
      <div class="text-subtitle-1">{{ balance.overall.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} kg</div>
    </v-col>
    <v-col cols="6" sm="3">
      <div class="text-caption">Cross Weight</div>
      <div class="text-subtitle-1">{{ balance.cross.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} kg</div>
    </v-col>
    <v-col cols="6" sm="3">
      <div class="text-caption">Balance Front</div>
      <div class="text-subtitle-1">
        {{ balance.rollBalance.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} %
      </div>
    </v-col>
    <v-col cols="6" sm="3">
      <div class="text-caption">Balance Left</div>
      <div class="text-subtitle-1">
        {{ balance.pitchBalance.toLocaleString('en-GB', { maximumFractionDigits: 2 }) }} %
      </div>
    </v-col>
  </v-row>
</template>

<script>
function calculateBalance(fl, fr, rl, rr) {
  /**
   * uses weights
   * ff: frontLeft
   * fr: frontRight
   * rl: rearLeft
   * rr: rearRight
   */
  fl = parseFloat(fl)
  fr = parseFloat(fr)
  rl = parseFloat(rl)
  rr = parseFloat(rr)
  const overall = fl + fr + rl + rr || 0
  const cross = fl + rr - (fr + rl) || 0
  const rollBalance = (100 * (fl + fr)) / overall || 0
  const pitchBalance = (100 * (fl + rl)) / overall || 0

  return { overall, rollBalance, pitchBalance, cross }
}

export default {
  name: 'SetupBalance',
  props: { config: Object, axes: Object },
  computed: {
    balance() {
      if (this.config) {
        return calculateBalance(
          this.config.frontLeft,
          this.config.frontRight,
          this.config.rearLeft,
          this.config.rearRight
        )
      } else {
        return calculateBalance(this.axes.front, this.axes.front, this.axes.rear, this.axes.rear)
      }
    }
  }
}
</script>
