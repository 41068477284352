<template>
  <div ref="parent" class="parent">
    <v-text-field
      v-model="value"
      :rules="rules"
      :label="dynamicLabel"
      :hint="hint"
      :suffix="suffix"
      required
      @focus="onFocus"
      @blur="onBlur"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveTextField',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    rules: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    shortLabel: {
      type: String,
      required: true
    },
    hint: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dynamicLabel: this.label,
      isFocused: false
    }
  },
  watch: {
    value() {
      this.updateLabel()
    }
  },
  methods: {
    updateLabel() {
      if (this.value || this.isFocused) {
        this.dynamicLabel = this.label
      } else {
        if (this.$refs.parent.clientWidth < 110) {
          this.dynamicLabel = this.shortLabel
        } else {
          this.dynamicLabel = this.label
        }
      }
    },
    onFocus() {
      this.isFocused = true
      this.updateLabel()
    },
    onBlur() {
      this.isFocused = false
      this.updateLabel()
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateLabel)
    this.updateLabel()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateLabel)
  }
}
</script>

<style scoped>
.parent {
  width: 100%;
}
</style>
