<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="localDate"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="localDate"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="localDate" scrollable first-day-of-week="1">
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(localDate)"> OK </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field v-model="local.title" :rules="$rules.required" label="Title" required />
        </v-col>
        <v-col cols="12" md="6">
          <v-select v-model="local.ground" :items="grounds" label="Ground" item-text="name" item-value="id" required />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="local.responsibleId"
            :items="users"
            label="Responsible"
            item-text="fullname"
            item-value="id"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="local.carId"
            :items="cars"
            label="Cars"
            item-text="selectionText"
            item-value="id"
            required
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-select
            v-model="local.baseSetupId"
            :items="setups"
            label="Setups"
            item-text="selectionText"
            item-value="id"
            :disabled="!setups.length"
            required
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="submit" :disabled="!valid">
            <slot />
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { cloneDeep as _cloneDeep } from 'lodash/lang'
import { DateTime } from 'luxon'

import { Usernames } from '@graphql/Users.gql'
import { CarInfo } from '@graphql/Cars.gql'
import { BaseSetupOverviews } from '@graphql/Setups.gql'

export default {
  name: 'TestingDaysDataForm',
  apollo: {
    cars: {
      query: CarInfo,
      update: (data) => {
        const cars = data.cars.map((car) => ({
          ...car,
          selectionText: `${car.name} (${car.season})`
        }))
        return cars
      }
    },
    users: { query: Usernames },
    setups: {
      query: BaseSetupOverviews,
      variables() {
        return { where: { carId: { equals: this.local.carId } } }
      },
      skip() {
        return !this.local.carId
      },
      update: (data) => {
        const setups = data.baseSetups.map((setup) => ({
          ...setup,
          selectionText: `${setup.title} (${DateTime.fromISO(setup.createdAt).toFormat('dd LLL yyyy')})`
        }))
        setups.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        return setups
      }
    }
  },
  props: {
    testingDay: {
      type: Object,
      default() {
        return {
          title: '',
          ground: '',
          responsibleId: '',
          carId: '',
          baseSetupId: ''
        }
      }
    },
    date: {
      type: String,
      required: true
    }
  },
  data: () => ({
    valid: false,
    local: {},
    localDate: '',
    grounds: [
      { id: 'WASEN', name: 'Wasen' },
      { id: 'RENNINGEN', name: 'Renningen' },
      { id: 'FICHTENBERG', name: 'Fichtenberg' },
      { id: 'SCHWIEBERDINGEN', name: 'Schwieberdingen' },
      { id: 'BOXBERG', name: 'Boxberg' },
      { id: 'NAGOLD', name: 'Nagold' },
      { id: 'GOEPPINGEN', name: 'Göppingen' },
      { id: 'HILTESTING', name: 'HiL-Testing' },
      { id: 'UNKNOWN', name: 'Unknown' }
    ],
    cars: [],
    users: [],
    setups: [],
    menu: false
  }),
  created() {
    this.local = _cloneDeep(this.testingDay)
    this.localDate = this.date.substring(0, 10)
  },
  watch: {
    date() {
      this.localDate = this.date.substring(0, 10)
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate()
      if (!this.valid) return
      const data = {
        title: this.local.title,
        ground: this.local.ground,
        responsibleId: this.local.responsibleId,
        carId: this.local.carId,
        baseSetupId: this.local.baseSetupId,
        date: this.localDate
      }
      this.$emit('submit', data)
      return data
    }
  }
}
</script>
