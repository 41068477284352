var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-4",attrs:{"to":_vm.runTo,"outlined":""}},[_c('v-row',[(_vm.showDay)?_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.run.title || 'Unnamed'))]),_c('v-card-subtitle',{staticClass:"pb-0"},[_vm._v(" "+_vm._s(_vm.run.day.title)+" - Run "+_vm._s(_vm.run.number)+" ")]),_c('v-card-subtitle',{staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm.run.day.ground)+", "+_vm._s(_vm.formattedDate)+" ")]),(_vm.run.success)?_c('v-alert',{attrs:{"text":"","dense":"","type":"success"}},[_vm._v("Run was successfull")]):_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v("Run was not successfull")])],1):_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.run.title || 'Unnamed'))]),_c('v-card-subtitle',[_vm._v("Run "+_vm._s(_vm.run.number)+" - "+_vm._s(_vm.run.manoeuvre.title))]),(_vm.run.success)?_c('v-alert',{attrs:{"text":"","dense":"","type":"success"}},[_vm._v("Run was successfull")]):_c('v-alert',{attrs:{"text":"","dense":"","type":"error"}},[_vm._v("Run was not successfull")])],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card-text',[_c('div',{staticClass:"text-overline"},[_vm._v("About")]),_c('div',{staticClass:"text-body-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-clock")])]}}])},[_c('span',[_vm._v("Start/End time")])]),_vm._v(" "+_vm._s(_vm.$luxon(_vm.run.start, 'HH:mm'))+" - "+_vm._s(_vm.$luxon(_vm.run.end, 'HH:mm'))+" ")],1),_c('div',{staticClass:"text-body-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-racing-helmet")])]}}])},[_c('span',[_vm._v("Driver")])]),_vm._v(" "+_vm._s(_vm.run.driverless ? 'Driverless' : _vm.run.driver.fullname)+_vm._s(_vm.run.second_driver ? ', ' + _vm.run.second_driver.fullname : '')+" ")],1),_c('div',{staticClass:"text-body-1"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"dense":""}},'v-icon',attrs,false),on),[_vm._v("mdi-account")])]}}])},[_c('span',[_vm._v("Responsible")])]),_vm._v(" "+_vm._s(_vm.run.responsible.fullname)+" ")],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[(_vm.run.driverless)?_c('v-alert',{attrs:{"text":"","dense":"","type":"info"}},[_vm._v("Driverless")]):_vm._e(),_c('v-card-text',[_c('div',{staticClass:"text-overline"},[_vm._v("Notes")]),_c('div',{staticClass:"text-body-1 text-multiline"},[_vm._v(_vm._s(_vm.run.notes))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }