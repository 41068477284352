<template>
  <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="config.rearHeaveRebound"
          :rules="$rules.requiredNumber"
          color="primary"
          label="Rear Heave Rebound (Zugstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
        <v-text-field
          v-model="config.rearHeaveBump"
          :rules="$rules.requiredNumber"
          color="info"
          label="Rear Heave Bump (Druckstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="config.frontHeaveRebound"
          :rules="$rules.requiredNumber"
          color="primary"
          label="Front Heave Rebound (Zugstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
        <v-text-field
          v-model="config.frontHeaveBump"
          :rules="$rules.requiredNumber"
          color="info"
          label="Front Heave Bump (Druckstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
      </v-col>
    </v-row>
    <v-row justify="center"
      ><v-col cols="auto">
        <v-img
          :src="
            $vuetify.theme.dark
              ? require('@assets/car-top-alpha-90_white.png')
              : require('@assets/car-top-alpha-90_black.png')
          "
          width="300"
          height="150"
        ></v-img> </v-col
    ></v-row>
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="config.rearRollRebound"
          :rules="$rules.requiredNumber"
          color="primary"
          label="Rear Roll Rebound (Zugstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
        <v-text-field
          v-model="config.rearRollBump"
          :rules="$rules.requiredNumber"
          color="info"
          label="Rear Roll Bump (Druckstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="config.frontRollRebound"
          :rules="$rules.requiredNumber"
          color="primary"
          label="Front Roll Rebound (Zugstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
        <v-text-field
          v-model="config.frontRollBump"
          :rules="$rules.requiredNumber"
          color="info"
          label="Front Roll Bump (Druckstufe)"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        />
      </v-col>
    </v-row>
    <v-switch :rules="$rules.required" label="Pressure checked?" />
  </v-form>
</template>

<script>
export default {
  name: 'SetupConfigDamperForm',
  model: {
    prop: 'config',
    event: 'input'
  },
  props: { unit: String, config: Object },
  data: () => ({
    valid: false
  }),
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
