<template>
  <v-card class="mt-4" :to="runTo" outlined>
    <v-row>
      <v-col v-if="showDay" cols="12" sm="6" md="3">
        <v-card-title>{{ run.title || 'Unnamed' }}</v-card-title>
        <v-card-subtitle class="pb-0"> {{ run.day.title }} - Run {{ run.number }} </v-card-subtitle>
        <v-card-subtitle class="pt-0"> {{ run.day.ground }}, {{ formattedDate }} </v-card-subtitle>
        <v-alert v-if="run.success" text dense type="success">Run was successfull</v-alert>
        <v-alert v-else text dense type="error">Run was not successfull</v-alert>
      </v-col>
      <v-col v-else cols="12" sm="6" md="3">
        <v-card-title>{{ run.title || 'Unnamed' }}</v-card-title>
        <v-card-subtitle>Run {{ run.number }} - {{ run.manoeuvre.title }}</v-card-subtitle>
        <v-alert v-if="run.success" text dense type="success">Run was successfull</v-alert>
        <v-alert v-else text dense type="error">Run was not successfull</v-alert>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-card-text>
          <div class="text-overline">About</div>

          <div class="text-body-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on">mdi-clock</v-icon>
              </template>
              <span>Start/End time</span>
            </v-tooltip>
            {{ $luxon(run.start, 'HH:mm') }} - {{ $luxon(run.end, 'HH:mm') }}
          </div>

          <div class="text-body-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on">mdi-racing-helmet</v-icon>
              </template>
              <span>Driver</span>
            </v-tooltip>
            {{ run.driverless ? 'Driverless' : run.driver.fullname
            }}{{ run.second_driver ? ', ' + run.second_driver.fullname : '' }}
          </div>

          <div class="text-body-1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon dense v-bind="attrs" v-on="on">mdi-account</v-icon>
              </template>
              <span>Responsible</span>
            </v-tooltip>
            {{ run.responsible.fullname }}
          </div>
        </v-card-text>
      </v-col>
      <v-col cols="12" md="6">
        <v-alert v-if="run.driverless" text dense type="info">Driverless</v-alert>
        <v-card-text>
          <div class="text-overline">Notes</div>
          <div class="text-body-1 text-multiline">{{ run.notes }}</div>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'TestingRunInfoOverviewCard',
  props: { run: { type: Object, required: true }, showDay: { type: Boolean, default: false } },
  computed: {
    runTo() {
      return { name: 'TestingRun', params: { id: this.run.id } }
    },
    formattedDate() {
      return DateTime.fromISO(this.run.day.date).toFormat('dd LLL yyyy')
    }
  }
}
</script>
