<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <!-- generate one input form for each lap -->
      <div class="overflow-hidden">
        <div v-for="(lap, i) in laps" :key="i">
          <v-container class="pa-0">
            <v-row
              class="ma-0 my-3 rounded"
              :class="{
                'grey darken-3': i % 2 == 0 && $vuetify.theme.dark,
                'grey lighten-4': i % 2 == 0 && !$vuetify.theme.dark
              }"
              dense
            >
              <!-- first small column for delete button -->
              <v-col cols="12" lg="1" class="mt-1 text-center">
                <p class="ma-0" id="lapnumber">{{ i + 1 }}</p>
                <v-btn @click="removeLap(i)" icon><v-icon>mdi-delete</v-icon></v-btn>
              </v-col>
              <!-- second column for times -->
              <v-col cols="12" md="12" lg="3" class="ma-0 mr-1 pa-0 pl-2">
                <!-- row for lap time -->
                <v-row class="ma-0 pa-0">
                  <v-col class="ma-0 pa-0 pt-3">
                    <v-text-field
                      class="ma-0 pa-0"
                      v-model="lap.time"
                      :rules="$rules.number"
                      label="Lap Time"
                      hint="Seperate with dot! E.g. '1234.23'"
                      suffix="s"
                      outlined
                      color="primary"
                    />
                  </v-col>
                </v-row>
                <!-- row for start time -->
                <v-row class="pa-0 ma-0">
                  <!-- column for text field -->
                  <v-col cols="8" class="pa-0 ma-0">
                    <testing-run-clock class="ma-0 pa-0" label="Start Time" v-model="lap.start" />
                  </v-col>
                  <!-- column for now button -->
                  <v-col cols="3" class="ma-0 pa-0">
                    <v-container>
                      <v-btn block outlined @click="setStartNow(i)" class="ma-0" text>Now!</v-btn>
                    </v-container>
                  </v-col>
                  <!-- row for start time -->
                  <v-row class="pa-0 ma-0">
                    <!-- column for text field -->
                    <v-col cols="8" class="ma-0 pa-0">
                      <testing-run-clock class="ma-0 pa-0" label="End Time" v-model="lap.end" />
                    </v-col>
                    <!-- column for now button -->
                    <v-col cols="3" class="ma-0 pa-0">
                      <v-container>
                        <v-btn block outlined @click="setEndNow(i)" class="ma-0" text>Now!</v-btn>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-row>
              </v-col>
              <!-- <v-divider vertical></v-divider> -->
              <!-- column for incidents -->
              <v-col id="incident-column" class="pa-0 mr-1 pl-2">
                <v-row class="ma-0 pt-3">
                  <v-col cols="6" class="ma-0 pa-0 pr-1">
                    <v-textarea
                      outlined
                      color="primary"
                      :rules="$rules.number"
                      label="Cone Count"
                      type="number"
                      auto-grow
                      v-model="lap.coneCount"
                      rows="1"
                    />
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0 pl-1">
                    <v-textarea
                      outlined
                      :rules="$rules.number"
                      color="primary"
                      label="Offtrack"
                      type="number"
                      auto-grow
                      v-model="lap.offTracks"
                      rows="1"
                    />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12" class="ma-0 pa-0">
                    <v-textarea
                      outlined
                      color="primary"
                      label="Incidents & Occurences"
                      height="100%"
                      v-model="lap.occurences"
                      rows="5"
                      auto-grow
                    />
                  </v-col>
                </v-row>
              </v-col>
              <!-- <v-divider vertical></v-divider> -->
              <!-- column for limits -->
              <v-col cols="12" md="12" lg="4" v-if="showLimits" class="ma-0 pa-0 pl-2">
                <v-row class="ma-0 pa-0 pt-3">
                  <v-col cols="4" class="ma-0 pa-0 pr-1">
                    <v-text-field
                      v-model="lap.torqueLimit"
                      :rules="$rules.number"
                      label="Torque +"
                      hint="Seperate with dot! E.g. '1234.23'"
                      suffix="Nm"
                      outlined
                      color="green"
                    />
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 ps-1">
                    <v-text-field
                      v-model="lap.negativeTorqueLimit"
                      :rules="$rules.number"
                      label="Torque -"
                      hint="Seperate with dot! E.g. '1234.23'"
                      suffix="Nm"
                      outlined
                      color="red"
                    />
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 pl-2">
                    <v-switch v-model="lap.torqueVectoring" label="TV" />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="4" class="ma-0 pa-0 pr-1">
                    <v-text-field
                      v-model="lap.powerLimit"
                      :rules="$rules.number"
                      label="Power +"
                      hint="Seperate with dot! E.g. '1234.23'"
                      suffix="kW"
                      outlined
                      color="green"
                    />
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 ps-1">
                    <v-text-field
                      v-model="lap.negativePowerLimit"
                      :rules="$rules.number"
                      label="Power -"
                      hint="Seperate with dot! E.g. '1234.23'"
                      suffix="kW"
                      outlined
                      color="red"
                    />
                  </v-col>
                  <v-col cols="4" class="ma-0 pa-0 pl-2">
                    <v-switch v-model="lap.tractionControl" label="TC" />
                  </v-col>
                </v-row>
                <v-row class="ma-0 pa-0">
                  <v-col cols="12 " class="ma-0 ps-0 py-0">
                    <v-text-field
                      v-model="lap.velocityLimit"
                      :rules="$rules.number"
                      label="Velocity Limit"
                      hint="Seperate with dot! E.g. '1234.23'"
                      suffix="m/s"
                      outlined
                      color="primary"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <v-divider />
        </div>
      </div>
      <v-divider class="mb-6" />

      <v-row align="center">
        <v-col cols="3" sm="2" lg="1">
          <div class="text-h4">{{ laps.length }}</div>
          Laps
        </v-col>
        <v-col cols="6" sm="3" lg="2">
          <v-text-field v-model="numNewLaps" label="Add new Laps" :rules="$rules.requiredNumber" />
        </v-col>
        <v-btn @click="addLap" small fab color="primary" :disabled="isNaN(numNewLaps) || isNaN(parseFloat(numNewLaps))">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-spacer />
        <v-col cols="12" sm="6" lg="3">
          <v-switch v-model="showLimits" label="Show Limits" />
        </v-col>
      </v-row>

      <v-expand-transition>
        <v-row v-show="showLimits">
          <!-- <dv style="position: sticky, top: 20px"> -->
          <v-col cols="1" class="pa-2 mb-10">
            <p class="text-h6 sideways">New Lap Defaults</p>
          </v-col>
          <v-col cols="11">
            <v-row align="center">
              <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  v-model="newLapDefaults.torqueLimit"
                  :rules="$rules.number"
                  label="Torque Limit"
                  hint="Seperate with dot! E.g. '1234.23'"
                  suffix="Nm"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  v-model="newLapDefaults.negativeTorqueLimit"
                  :rules="$rules.number"
                  label="Negative Torque Limit (Reku)"
                  hint="Seperate with dot! E.g. '1234.23'"
                  suffix="Nm"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="3">
                <v-switch v-model="newLapDefaults.torqueVectoring" label="Enabled Torque Vecotring (TV)" />
              </v-col>

              <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  v-model="newLapDefaults.velocityLimit"
                  :rules="$rules.number"
                  label="Velocity Limit"
                  hint="Seperate with dot! E.g. '1234.23'"
                  suffix="m/s"
                />
              </v-col>

              <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  v-model="newLapDefaults.powerLimit"
                  :rules="$rules.number"
                  label="Power Limit"
                  hint="Seperate with dot! E.g. '1234.23'"
                  suffix="kW"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="3">
                <v-text-field
                  v-model="newLapDefaults.negativePowerLimit"
                  :rules="$rules.number"
                  label="Negative Power Limit (Reku)"
                  hint="Seperate with dot! E.g. '1234.23'"
                  suffix="kW"
                />
              </v-col>
              <v-col cols="12" sm="6" lg="3">
                <v-switch v-model="newLapDefaults.tractionControl" label="Enabled Traction Control (TC)" />
              </v-col>

              <v-col cols="12" sm="6" lg="3">
                <v-btn color="secondary" text outlined @click="showApplyToAllDialog = true">Apply to all</v-btn>
              </v-col>
            </v-row>
          </v-col>

          <app-apply-dialog
            :show="showApplyToAllDialog"
            @apply="applyLimitsToAll"
            @close="showApplyToAllDialog = false"
          >
            This will apply the following values to
            <p class="font-weight-bold" style="display: inline">ALL</p>
            Laps.
            <br />
            <div v-if="newLapDefaults.velocityLimit">
              Velocity Limit:
              <p class="font-weight-bold primary--text" style="display: inline">{{ newLapDefaults.velocityLimit }}</p>
            </div>
            <div v-if="newLapDefaults.powerLimit">
              Power Limit:
              <p class="font-weight-bold primary--text" style="display: inline">{{ newLapDefaults.powerLimit }}</p>
            </div>
            <div v-if="newLapDefaults.negativePowerLimit">
              Negative Power Limit (Reku):
              <p class="font-weight-bold primary--text" style="display: inline">
                {{ newLapDefaults.negativePowerLimit }}
              </p>
            </div>
            <div v-if="newLapDefaults.torqueLimit">
              Torque Limit:
              <p class="font-weight-bold primary--text" style="display: inline">{{ newLapDefaults.torqueLimit }}</p>
            </div>
            <div v-if="newLapDefaults.negativeTorqueLimit">
              Negative Torque Limit (Reku):
              <p class="font-weight-bold primary--text" style="display: inline">
                {{ newLapDefaults.negativeTorqueLimit }}
              </p>
            </div>
            <div>
              Torque Vectoring:
              <p class="font-weight-bold primary--text" style="display: inline">
                {{ newLapDefaults.torqueVectoring ? 'On' : 'Off' }}
              </p>
            </div>
            <div>
              Traction Control:
              <p class="font-weight-bold primary--text" style="display: inline">
                {{ newLapDefaults.tractionControl ? 'On' : 'Off' }}
              </p>
            </div>
          </app-apply-dialog>

          <!-- </div> -->
        </v-row>
      </v-expand-transition>
    </v-container>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'
import AppApplyDialog from '@components/app/ApplyDialog'
import TestingRunClock from '@components/testingRuns/Clock.vue'
export default {
  name: 'TestingRunLapsForm',
  components: {
    AppApplyDialog,
    TestingRunClock
  },
  model: {
    prop: 'laps',
    event: 'input'
  },
  props: { laps: Array },
  data: () => ({
    backgroundColor: 'white',
    startTimeOpen: false,
    startTimeLapIdx: 0,
    endTimeOpen: false,
    endTimeLapIdx: 0,
    valid: false,
    showLimits: false,
    showApplyToAllDialog: false,
    numNewLaps: 1,
    newLapDefaults: {
      velocityLimit: '',
      powerLimit: '',
      negativePowerLimit: '',
      torqueLimit: '',
      negativeTorqueLimit: '',
      torqueVectoring: false,
      tractionControl: false
    }
  }),
  methods: {
    setStart(i) {
      this.$refs.startTimeDialog[i].save(this.laps[i].start)
    },
    setEnd(i) {
      this.$refs.endTimeDialog[i].save(this.laps[i].end)
    },
    setStartNow(i) {
      const now = DateTime.now()
      if (this.laps[i]) this.laps[i].start = now.toFormat('HH:mm')
    },
    setEndNow(i) {
      const now = DateTime.now()
      if (this.laps[i]) this.laps[i].end = now.toFormat('HH:mm')
    },
    getLaps() {
      const today = DateTime.now().toISODate()
      return this.laps.map((lap, i) => {
        const start = lap.start ? DateTime.fromISO(`${today}T${lap.start}`).toISO() : null
        const end = lap.end ? DateTime.fromISO(`${today}T${lap.end}`).toISO() : null
        const l = {
          occurences: lap.occurences,
          time: parseFloat(lap.time),
          start,
          end,
          offTracks: parseInt(lap.offTracks),
          coneCount: parseInt(lap.coneCount),
          velocityLimit: parseFloat(lap.velocityLimit),
          powerLimit: parseFloat(lap.powerLimit),
          negativePowerLimit: parseFloat(lap.negativePowerLimit),
          torqueLimit: parseFloat(lap.torqueLimit),
          negativeTorqueLimit: parseFloat(lap.negativeTorqueLimit),
          torqueVectoring: lap.torqueVectoring,
          tractionControl: lap.tractionControl,
          number: i + 1
        }
        //if (lap.id) l.id = lap.id
        return l
      })
    },
    addLap() {
      for (let _ = 0; _ < this.numNewLaps; _++) {
        const lapTemplate = {
          occurences: '',
          time: '',
          start: undefined,
          end: undefined,
          offTracks: undefined,
          coneCount: undefined,
          velocityLimit: this.newLapDefaults.velocityLimit,
          powerLimit: this.newLapDefaults.powerLimit,
          negativePowerLimit: this.newLapDefaults.negativePowerLimit,
          torqueLimit: this.newLapDefaults.torqueLimit,
          negativeTorqueLimit: this.newLapDefaults.negativeTorqueLimit,
          torqueVectoring: this.newLapDefaults.torqueVectoring,
          tractionControl: this.newLapDefaults.tractionControl
        }
        this.laps.push(lapTemplate)
      }
    },
    removeLap(i) {
      i = isNaN(i) ? -1 : i
      if (this.laps.length === 0) return
      this.laps.splice(i, 1)
    },
    applyLimitsToAll() {
      this.laps.forEach((lap) => {
        if (this.newLapDefaults.velocityLimit) lap.velocityLimit = this.newLapDefaults.velocityLimit
        if (this.newLapDefaults.powerLimit) lap.powerLimit = this.newLapDefaults.powerLimit
        if (this.newLapDefaults.negativePowerLimit) lap.negativePowerLimit = this.newLapDefaults.negativePowerLimit
        if (this.newLapDefaults.torqueLimit) lap.torqueLimit = this.newLapDefaults.torqueLimit
        if (this.newLapDefaults.negativeTorqueLimit) lap.negativeTorqueLimit = this.newLapDefaults.negativeTorqueLimit
        lap.torqueVectoring = this.newLapDefaults.torqueVectoring
        lap.tractionControl = this.newLapDefaults.tractionControl
      })
      this.showApplyToAllDialog = false
    },
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>

<style scoped>
.sideways {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
}
</style>
