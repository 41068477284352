<template>
  <v-dialog ref="timeDialog" v-model="dialogOpen" :return-value.sync="value" width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        v-model="value"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        class="ma-0 pa-0"
      ></v-text-field>
    </template>
    <v-time-picker v-if="dialogOpen" v-model="value" full-width>
      <v-spacer></v-spacer>
      <v-btn text color="secondary" @click="dialogOpen = false"> Cancel </v-btn>
      <v-btn
        text
        color="primary"
        @click="
          $refs.timeDialog.save(value)
          $emit('input', value)
        "
      >
        OK
      </v-btn>
    </v-time-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'TestingRunClock',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: { value: String, label: String },
  data: () => ({
    dialogOpen: false
  })
}
</script>
