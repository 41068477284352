<template>
  <v-form ref="form" v-model="valid">
    <v-row>
      <v-col cols="6">
        <v-dialog
          ref="startTimeDialog"
          v-model="startTimeOpen"
          :return-value.sync="local.start"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="local.start"
              label="Start Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              :rules="$rules.required"
            ></v-text-field>
          </template>
          <v-time-picker v-if="startTimeOpen" v-model="local.start" full-width>
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="startTimeOpen = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.startTimeDialog.save(local.start)"> OK </v-btn>
          </v-time-picker>
        </v-dialog>
        <v-btn @click="setStartNow" text>Now!</v-btn>
      </v-col>
      <v-col cols="6">
        <v-dialog ref="endTimeDialog" v-model="endTimeOpen" :return-value.sync="local.end" persistent width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="local.end"
              label="End Time"
              prepend-icon="mdi-clock-time-four-outline"
              readonly
              v-bind="attrs"
              v-on="on"
              required
              :rules="$rules.required"
            ></v-text-field>
          </template>
          <v-time-picker v-if="endTimeOpen" v-model="local.end" full-width>
            <v-spacer></v-spacer>
            <v-btn text color="secondary" @click="endTimeOpen = false"> Cancel </v-btn>
            <v-btn text color="primary" @click="$refs.endTimeDialog.save(local.end)"> OK </v-btn>
          </v-time-picker>
        </v-dialog>
        <v-btn @click="setEndNow" text>Now!</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-select
          v-model="local.weatherConditions"
          :items="weatherConditions"
          item-text="name"
          item-value="id"
          label="Weather Conditions"
          return-object
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="local.trackConditions"
          :items="trackConditions"
          item-text="name"
          item-value="id"
          label="Track Conditions"
          return-object
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="local.airTemperature"
          :rules="$rules.number"
          label="Air Temperature"
          hint="Seperate with dot! E.g. '1234.23'"
          suffix="°C"
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="local.trackTemperature"
          :rules="$rules.number"
          label="Track Temperature"
          hint="Seperate with dot! E.g. '1234.23'"
          suffix="°C"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-text-field
          v-model="local.windSpeed"
          :rules="$rules.number"
          label="Wind Speed"
          hint="Seperate with dot! E.g. '1234.23'"
          suffix="m/s"
        />
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="local.windDirection"
          :items="windDirections"
          item-text="name"
          item-value="id"
          label="Wind Direction"
          return-object
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'TestingRunTimeWeatherForm',
  model: {
    prop: 'local',
    event: 'input'
  },
  props: { local: Object },

  data: () => ({
    valid: false,
    startTimeOpen: false,
    endTimeOpen: false,
    weatherConditions: [
      { id: 'CLEAR', name: 'Clear' },
      { id: 'FEW_CLOUDS', name: 'Few Clouds' },
      { id: 'CLOUDS', name: 'Clouds' },
      { id: 'SHOWER_RAIN', name: 'Shower Rain' },
      { id: 'RAIN', name: 'Rain' },
      { id: 'MIST', name: 'Mist' },
      { id: 'SNOW', name: 'Snow' },
      { id: 'STORM', name: 'Storm' }
    ],
    trackConditions: [
      { id: 'DRY', name: 'Dry' },
      { id: 'DAMP', name: 'Damp' },
      { id: 'SLIPPERY', name: 'Slippery' },
      { id: 'WET', name: 'Wet' }
    ],
    windDirections: [
      { id: 'NORTH', name: 'N' },
      { id: 'NORTHEAST', name: 'NE' },
      { id: 'EAST', name: 'E' },
      { id: 'SOUTHEAST', name: 'SE' },
      { id: 'SOUTH', name: 'S' },
      { id: 'SOUTHWEST', name: 'SW' },
      { id: 'WEST', name: 'W' },
      { id: 'NORTHWEST', name: 'NW' }
    ]
  }),
  created() {
    if (this.local.weatherConditions)
      this.local.weatherConditions = this.weatherConditions.find((c) => c.id === this.local.weatherConditions)
    if (this.local.trackConditions)
      this.local.trackConditions = this.trackConditions.find((c) => c.id === this.local.trackConditions)
    if (this.local.windDirection)
      this.local.windDirection = this.windDirections.find((c) => c.id === this.local.windDirection)
  },
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    },
    setStartNow() {
      const now = DateTime.now()
      this.local.start = now.toFormat('HH:mm')
    },
    setEndNow() {
      const now = DateTime.now()
      this.local.end = now.toFormat('HH:mm')
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
