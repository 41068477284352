<template>
  <v-form class="pa-5" ref="form" v-model="valid">
    <v-row>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="config.frontLeftFront"
          :rules="$rules.requiredNumber"
          label="Front Left - Front"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <v-text-field
          v-model="config.frontLeftRear"
          :rules="$rules.requiredNumber"
          label="Front Left - Rear"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <div v-if="$vuetify.breakpoint.name !== 'xs'" style="height: 10px"></div>
        <v-text-field
          v-model="config.rearLeftFront"
          :rules="$rules.requiredNumber"
          label="Rear Left - Front"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <v-text-field
          v-model="config.rearLeftRear"
          :rules="$rules.requiredNumber"
          label="Rear Left - Rear"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="4">
        <v-row justify="center"
          ><v-col cols="auto">
            <v-img
              :src="
                $vuetify.theme.dark
                  ? require('@assets/car-top-alpha_white.png')
                  : require('@assets/car-top-alpha_black.png')
              "
              width="150"
              height="300"
            ></v-img> </v-col
        ></v-row>
      </v-col>
      <v-col cols="12" sm="4">
        <v-text-field
          v-model="config.frontRightFront"
          :rules="$rules.requiredNumber"
          label="Front Right - Front"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <v-text-field
          v-model="config.frontRightRear"
          :rules="$rules.requiredNumber"
          label="Front Right - Rear"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <div v-if="$vuetify.breakpoint.name !== 'xs'" style="height: 10px"></div>
        <v-text-field
          v-model="config.rearRightFront"
          :rules="$rules.requiredNumber"
          label="Rear Right - Front"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
        <v-text-field
          v-model="config.rearRightRear"
          :rules="$rules.requiredNumber"
          label="Rear Right - Rear"
          hint="Seperate with dot! E.g. '1234.23'"
          :suffix="unit"
          required
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
export default {
  name: 'SetupWheelDoubleForm',
  model: {
    prop: 'config',
    event: 'input'
  },
  props: {
    unit: String,
    config: Object
  },
  data: () => ({
    valid: false
  }),
  methods: {
    validate() {
      const valid = this.$refs.form.validate()
      this.$emit('validate', valid)
      return valid
    }
  },
  watch: {
    valid() {
      this.$emit('validate', this.valid)
    }
  }
}
</script>
