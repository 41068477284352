<template>
  <div v-if="testingDay">
    <v-img src="@assets/sleek21.jpg" height="250" gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"> </v-img>
    <v-container v-if="testingDay">
      <v-row>
        <v-col cols="12" sm="4" md="3" class="mt-2">
          <v-btn @click="goBack" color="primary" dark class="mb-4">
            <v-icon>mdi-arrow-left</v-icon>
            Go Back
          </v-btn>
          <testing-day-info-card :testingDay="testingDay">
            <v-spacer></v-spacer>
            <v-btn v-if="isAdmin" @click="showDeleteDialog = true" text> Delete </v-btn>
            <v-btn v-if="isMod" @click="showEditDialog = true"> Edit </v-btn>
          </testing-day-info-card>
        </v-col>
        <v-col cols="12" sm="8" md="9" class="mt-2">
          <v-btn :disabled="!testingDay.baseSetup" @click="showCreateRun = true" color="primary" block class=""
            >Add run</v-btn
          >

          <testing-run-info-overview-card v-for="run in testingDay.testingRuns" :key="run.number" :run="run" />
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showCreateRun" fullscreen>
      <v-sheet min-height="100%" :color="this.$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-5'">
        <v-toolbar color="primary" style="position: fixed" absolute collapse>
          <v-btn icon @click="showCreateRun = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Create new Run</v-toolbar-title>
        </v-toolbar>
        <testing-run-data-form stage="create" :testingDay="testingDay" @submit="createRun" />
      </v-sheet>
    </v-dialog>

    <v-dialog v-model="showEditDialog" max-width="500">
      <v-card>
        <v-card-title> Update Testing Day Info </v-card-title>
        <v-card-text>
          <testing-days-data-form :date="testingDay.date" :testingDay="testingDay" @submit="updateDay"
            >Update</testing-days-data-form
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <app-delete-dialog :show="showDeleteDialog" @delete="deleteDay" @close="showDeleteDialog = false">
      Delete a Testing Day will also delete connected Testing Runs! Are you sure your want delete this Testing Day?
    </app-delete-dialog>
  </div>

  <v-container v-else class="fill-height">
    <v-row justify="center" align="center">
      <div class="text-h3 mb-6">Day does not exist in database yet! Please provide one!</div>
      <v-btn :to="{ name: 'Testing' }" color="primary">To Calendar</v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { DateTime } from 'luxon'

import AppDeleteDialog from '@components/app/DeleteDialog'
import TestingDayInfoCard from '@components/testingDays/InfoCard.vue'
import TestingDaysDataForm from '@components/testingDays/DataForm.vue'
import TestingRunInfoOverviewCard from '@/components/testingRuns/InfoOverviewCard.vue'
import TestingRunDataForm from '@components/testingRuns/DataForm.vue'

import { TestingDay, UpdateTestingDay, DeleteTestingDay } from '@graphql/TestingDays.gql'
import { CreateTestingRun } from '@graphql/TestingRuns.gql'
import { UserInfo } from '@graphql/Users.gql'

export default {
  name: 'TestingDayPage',
  components: {
    TestingDayInfoCard,
    AppDeleteDialog,
    TestingDaysDataForm,
    TestingRunInfoOverviewCard,
    TestingRunDataForm
  },
  apollo: {
    testingDay: {
      query: TestingDay,
      variables() {
        return { where: { date: this.$route.params.date } }
      }
    },
    loggedUser: { query: UserInfo }
  },
  data: () => ({
    showCreateRun: false,
    showEditDialog: false,
    showDeleteDialog: false,
    testingDay: undefined,
    loggedUser: undefined
  }),
  computed: {
    date() {
      return DateTime.fromISO(this.$route.params.date)
    },
    isMod() {
      if (!this.loggedUser) return false
      if (this.loggedUser.role !== 'MODERATOR' && this.loggedUser.role !== 'ADMIN') return false
      return true
    },
    isAdmin() {
      if (!this.loggedUser) return false
      if (this.loggedUser.role !== 'ADMIN') return false
      return true
    }
  },
  methods: {
    goBack() {
      // Implement your logic to go back here
      // For example, you can use Vue Router's $router to navigate back
      //read current route
      const currentRoute = this.$router.currentRoute
      //the part after the route looks like this: /testing/2020-01-01 get the month and year
      const date = currentRoute.path.split('/').pop()
      
      const query = {}
      // add date in this format yyyy-MM to the query
      query.month = date.substring(0, 7)
      //go back to the testing page
      this.$router.push({ name: 'Testing', query: query })
    },

    createRun({ data }) {
      if (!this.testingDay.baseSetup) {
        this.$store.commit('openErrorDialog', 'Unable to create Run. Setup does not exist!')
        return
      }
      const lastRun = this.testingDay.testingRuns[this.testingDay.testingRuns.length - 1]
      const newNumber = lastRun ? lastRun.number + 1 : 1
      const variables = {
        data: {
          dayId: this.testingDay.id,
          title: data.title,
          number: newNumber,
          driverId: data.driverId,
          secondDriverId: data.secondDriverId,
          manoeuvreId: data.manoeuvreId,
          notes: data.notes,
          success: data.success,
          driverless: data.driverless,
          start: data.start,
          end: data.end,
          airTemperature: data.airTemperature,
          trackTemperature: data.trackTemperature,
          weatherConditions: data.weatherConditions,
          trackConditions: data.trackConditions,
          camera: data.camera,
          lidar: data.lidar,
          motionController: data.motionController,
          motionPlanning: data.motionPlanning,
          slam: data.slam,
          hvSocStart: data.hvSocStart,
          hvSocEnd: data.hvSocEnd,
          lvSocStart: data.lvSocStart,
          lvSocEnd: data.lvSocEnd,
          coldTyrePressure: { create: data.coldTyrePressure },
          warmupTyrePressure: { create: data.warmupTyrePressure },
          warmTyrePressure: { create: data.warmTyrePressure },
          laps: { createMany: { data: data.laps } },
          setup: {
            create: {
              dvMounted: data.setup.dvMounted,
              tyres: data.setup.tyres,
              tyreAge: data.setup.tyreAge,
              tyreSet: data.setup.tyreSet,
              springConfig: { create: data.setup.springConfig },
              damperConfig: { create: data.setup.damperConfig },
              weightConfig: { create: data.setup.weightConfig },
              rideHeightConfig: { create: data.setup.rideHeightConfig },
              toeConfig: { create: data.setup.toeConfig },
              camberConfig: { create: data.setup.camberConfig },
              targetTyrePressure: { create: data.setup.targetTyrePressure }
            }
          }
        }
      }
      this.$apollo
        .mutate({ mutation: CreateTestingRun, variables })
        .then(({ data }) => {
          this.testingDay.testingRuns.push(data.createTestingRun)
          this.showCreateRun = false
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    },
    updateDay(data) {
      const variables = {
        where: { date: new Date(this.$route.params.date) },
        data: {
          title: { set: data.title },
          ground: { set: data.ground },
          date: { set: new Date(data.date) },
          responsibleId: { set: data.responsibleId },
          baseSetupId: { set: data.baseSetupId },
          carId: { set: data.carId }
        }
      }
      this.$apollo
        .mutate({ mutation: UpdateTestingDay, variables })
        .then(({ data: { updateTestingDay } }) => {
          const date = updateTestingDay.date.substr(0, 10)
          if (this.$route.params.date !== date) {
            this.$router.push({ name: 'TestingDay', params: { date } })
            this.showEditDialog = false
            return
          }
          this.testingDay = updateTestingDay
          this.showEditDialog = false
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
    },
    deleteDay() {
      const variables = { where: { date: new Date(this.$route.params.date) } }
      this.$apollo
        .mutate({ mutation: DeleteTestingDay, variables })
        .then(() => {
          this.$router.push({ name: 'Testing' })
        })
        .catch((res) => {
          this.$store.commit('openErrorDialog', res)
        })
      this.showDeleteDialog = false
    }
  }
}
</script>
