<template>
  <v-dialog v-model="isOpen" :fullscreen="fullscreen">
    <template v-slot:activator="{ on, attrs }">
      <v-btn tile block color="accent" v-bind="attrs" v-on="on"> Shim Calculator </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary"> Shim Calculator </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" lg="6">
            <div class="text-h6">Current state</div>
            <setup-wheel-form v-model="current" unit="kg" />
            <setup-balance class="px-6" :config="current" />
            <v-divider v-if="fullscreen"></v-divider>
          </v-col>

          <v-divider v-if="!fullscreen" vertical></v-divider>

          <v-col cols="12" lg="6">
            <div class="text-h6">Shims</div>
            <setup-wheel-info :config="shims" unit="mm" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="copyCurrent"> Copy current </v-btn>
        <v-btn color="primary" text @click="isOpen = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SetupBalance from '@components/setup/ConfigBalance.vue'
import SetupWheelForm from '@components/setup/WheelForm.vue'
import SetupWheelInfo from '@components/setup/WheelInfo.vue'

function calcShims(value) {
  const parsedValue = value.toLocaleString('en-GB', { maximumFractionDigits: 2 })
  return value === 0 ? 0 : value > 0 ? `Add ${parsedValue}` : `Remove ${parsedValue * -1}`
}

/**
 * parses all values of an object to float
 */
function objectParseFloat(o) {
  return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, parseFloat(v)]))
}

export default {
  name: 'SetupConfigWeightHelper',
  components: { SetupBalance, SetupWheelForm, SetupWheelInfo },
  props: { shimFactor: Number },
  data: () => ({
    current: {
      frontLeft: undefined,
      frontRight: undefined,
      rearLeft: undefined,
      rearRight: undefined
    },
    target: 0,
    isOpen: false
  }),
  methods: {
    copyCurrent() {
      const current = objectParseFloat(this.current)
      this.$emit('copy', {
        frontLeft: current.frontLeft.toFixed(2),
        frontRight: current.frontRight.toFixed(2),
        rearLeft: current.rearLeft.toFixed(2),
        rearRight: current.rearRight.toFixed(2)
      })
      this.isOpen = false
    },
    open() {
      this.isOpen = true
    }
  },
  computed: {
    shims() {
      const front_factor = this.shimFactor || 0.106
      const rear_factor = this.shimFactor || 0.106

      const current = objectParseFloat(this.current)

      const front_swap = (current.frontLeft - current.frontRight) * front_factor || 0
      const rear_swap = (current.rearLeft - current.rearRight) * rear_factor || 0

      return {
        frontLeft: calcShims(front_swap * -1),
        frontRight: calcShims(front_swap),
        rearLeft: calcShims(rear_swap * -1),
        rearRight: calcShims(rear_swap)
      }
    },
    fullscreen() {
      return ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)
    }
  }
}
</script>
