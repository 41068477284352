<template>
  <v-form ref="form" v-model="valid">
    <v-container>
      <v-row>
        <v-col cols="12" lg="4">
          <v-card outlined height="100%">
            <v-card-text>
              <p class="text-overline">Overall Info</p>
              <v-row>
                <v-col>
                  <v-switch v-model="local.success" label="Run overall Successful?"></v-switch>
                </v-col>
                <v-col>
                  <v-switch v-model="local.driverless" label="Driverless?"></v-switch>
                </v-col>
              </v-row>
              <v-text-field v-model="local.title" label="Title" />

              <v-select
                v-model="local.manoeuvreId"
                :items="manoeuvres"
                label="Manoeuvre"
                item-text="title"
                item-value="id"
                required
                :rules="$rules.required"
              />
              <v-select
                v-model="local.driverId"
                :items="users"
                label="Driver"
                item-text="fullname"
                item-value="id"
                :disabled="local.driverless"
                :required="!local.driverless"
                :rules="local.driverless ? [] : $rules.required"
              />
              <v-select
                v-model="local.secondDriverId"
                :items="users"
                label="Second Driver"
                item-text="fullname"
                item-value="id"
                :disabled="local.driverless"
              />
              <v-textarea v-model="local.notes" label="Notes" auto-grow />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-card outlined height="100%">
            <v-card-text>
              <p class="text-overline">Time and Weather</p>
              <testing-run-time-weather-form ref="timeWeatherForm" v-model="local" @validate="valid = $event" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="4">
          <v-card outlined height="100%">
            <v-card-text>
              <p class="text-overline">Electrical Components</p>
              <testing-run-soc-form ref="socForm" v-model="local" @validate="valid = $event" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6" lg="12">
          <v-card outlined height="100%">
            <v-expansion-panels :disabled="!local.driverless">
              <v-expansion-panel>
                <v-expansion-panel-header> <p class="text-overline">Driverless Components</p></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <testing-run-driverless-form ref="driverlessForm" v-model="local" @validate="valid = $event" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="invalidForm">
        <v-col cols="12">
          <v-alert text type="error">The form "{{ invalidForm }}" is invalid! Please review your inputs!</v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-spacer></v-spacer>
        <v-btn v-if="stage === 'create'" color="primary" @click="submit"> Create </v-btn>
        <v-btn v-if="stage === 'update'" text @click="submit(false)"> Save </v-btn>
        <v-btn v-if="stage === 'update'" color="primary" @click="submit(true)"> Update & Close </v-btn>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card outlined height="100%">
            <v-card-text>
              <p class="text-overline">Tyres</p>
              <testing-run-tyre-form ref="tyreForm" v-model="local" @validate="valid = $event" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined height="100%">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header> <p class="text-overline">Setup</p></v-expansion-panel-header>
                <v-expansion-panel-content>
                  <testing-run-setup-form
                    ref="setupForm"
                    v-model="local"
                    :testingDay="testingDay"
                    @validate="valid = $event"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined height="100%">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p class="text-overline">Endurance Lap Calculator</p></v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <testing-run-lap-calculator />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-card outlined height="100%">
            <v-card-text>
              <p class="text-overline">Laps</p>
              <testing-run-laps-form ref="lapsForm" v-model="local.laps" @validate="valid = $event" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="invalidForm">
        <v-col cols="12">
          <v-alert text type="error">The form "{{ invalidForm }}" is invalid! Please review your inputs!</v-alert>
        </v-col>
      </v-row>
      <v-row no-gutters class="mt-3">
        <v-spacer></v-spacer>
        <v-btn v-if="stage === 'create'" color="primary" @click="submit"> Create </v-btn>
        <v-btn v-if="stage === 'update'" text @click="submit(false)"> Save </v-btn>
        <v-btn v-if="stage === 'update'" color="primary" @click="submit(true)"> Update & Close </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { DateTime } from 'luxon'
import { cloneDeep as _cloneDeep } from 'lodash/lang'

import TestingRunTimeWeatherForm from '@components/testingRuns/TimeWeatherForm.vue'
import TestingRunSocForm from '@components/testingRuns/SocForm.vue'
import TestingRunDriverlessForm from '@components/testingRuns/DriverlessForm.vue'
import TestingRunTyreForm from '@components/testingRuns/TyreForm.vue'
import TestingRunSetupForm from '@components/testingRuns/SetupForm.vue'
import TestingRunLapsForm from '@components/testingRuns/LapsForm.vue'
import TestingRunLapCalculator from '@components/testingRuns/LapCalculator.vue'

import { Usernames } from '@graphql/Users.gql'
import { Manoeuvres } from '@graphql/Manoeuvres.gql'

const defaultWheels = {
  frontLeft: '',
  frontRight: '',
  rearLeft: '',
  rearRight: ''
}

function parseAxis(config) {
  if (!config) return false
  const values = [config.front, config.rear].map((v) => parseFloat(v))
  if (!values.every((v) => !isNaN(v))) return false
  return {
    front: values[0],
    rear: values[1]
  }
}

function parseSpringAxis(config) {
  if (!config) return false
  const values = [config.frontHeave, config.frontRoll, config.rearHeave, config.rearRoll].map((v) => parseFloat(v))
  if (!values.every((v) => !isNaN(v))) return false
  return {
    frontHeave: values[0],
    frontRoll: values[1],
    rearHeave: values[2],
    rearRoll: values[3]
  }
}

function parseDamperAxis(config) {
  if (!config) return false
  const values = [
    config.frontHeaveRebound,
    config.frontHeaveBump,
    config.frontRollRebound,
    config.frontRollBump,
    config.rearHeaveRebound,
    config.rearHeaveBump,
    config.rearRollRebound,
    config.rearRollBump
  ].map((v) => parseFloat(v))
  if (!values.every((v) => !isNaN(v))) return false
  return {
    frontHeaveRebound: values[0],
    frontHeaveBump: values[1],
    frontRollRebound: values[2],
    frontRollBump: values[3],
    rearHeaveRebound: values[4],
    rearHeaveBump: values[5],
    rearRollRebound: values[6],
    rearRollBump: values[7]
  }
}

function parseWheels(config) {
  if (!config) return false
  const values = [config.frontLeft, config.frontRight, config.rearLeft, config.rearRight].map((v) => parseFloat(v))
  if (!values.every((v) => !isNaN(v))) return false
  return {
    frontLeft: values[0],
    frontRight: values[1],
    rearLeft: values[2],
    rearRight: values[3]
  }
}

export default {
  name: 'TestingRunDataForm',
  components: {
    TestingRunTimeWeatherForm,
    TestingRunSocForm,
    TestingRunDriverlessForm,
    TestingRunTyreForm,
    TestingRunSetupForm,
    TestingRunLapsForm,
    TestingRunLapCalculator
  },
  apollo: {
    manoeuvres: { query: Manoeuvres },
    users: { query: Usernames }
  },
  props: {
    // Is either 'create' or 'update'
    stage: {
      type: String,
      required: true
    },
    testingDay: {
      type: Object,
      required: true
    },
    testingRun: {
      type: Object,
      default() {
        const now = DateTime.now()
        return {
          driverId: undefined,
          secondDriverId: undefined,
          manoeuvreId: undefined,
          title: '',
          notes: '',
          success: false,
          driverless: false,
          start: now.toFormat('HH:mm'),
          end: now.toFormat('HH:mm'),
          airTemperature: undefined,
          trackTemperature: undefined,
          weatherConditions: undefined,
          trackConditions: undefined,
          windSpeed: undefined,
          windDirection: undefined,
          camera: undefined,
          lidar: undefined,
          motionController: undefined,
          motionPlanning: undefined,
          slam: undefined,
          hvSocStart: undefined,
          hvSocEnd: undefined,
          lvSocStart: undefined,
          lvSocEnd: undefined,
          coldTyrePressure: {
            frontLeft: undefined,
            frontRight: undefined,
            rearLeft: undefined,
            rearRight: undefined
          },
          warmupTyrePressure: {
            frontLeft: undefined,
            frontRight: undefined,
            rearLeft: undefined,
            rearRight: undefined
          },
          warmTyrePressure: {
            frontLeft: undefined,
            frontRight: undefined,
            rearLeft: undefined,
            rearRight: undefined
          },
          setup: false,
          laps: []
        }
      }
    }
  },
  data: () => ({
    valid: false,
    invalidForm: undefined,
    local: {},
    users: [],
    manoeuvres: []
  }),
  created() {
    this.local = _cloneDeep(this.testingRun)
    this.local.start = DateTime.fromISO(this.local.start).toFormat('HH:mm')
    this.local.end = DateTime.fromISO(this.local.end).toFormat('HH:mm')

    this.local.laps = this.local.laps.map((lap) => {
      lap.start = lap.start ? DateTime.fromISO(lap.start).toFormat('HH:mm') : undefined
      lap.end = lap.start ? DateTime.fromISO(lap.end).toFormat('HH:mm') : undefined
      return lap
    })

    if (!this.local.setup) this.local.setup = _cloneDeep(this.testingDay.baseSetup.setup)

    if (!this.local.coldTyrePressure) this.local.coldTyrePressure = _cloneDeep(defaultWheels)

    if (!this.local.warmupTyrePressure) this.local.warmupTyrePressure = _cloneDeep(defaultWheels)

    if (!this.local.warmTyrePressure) this.local.warmTyrePressure = _cloneDeep(defaultWheels)
  },
  methods: {
    submit(close) {
      if (!this.$refs.form.validate()) {
        this.invalidForm = 'Info'
        return
      }
      if (!this.$refs.timeWeatherForm.validate()) {
        this.invalidForm = 'Time & Weather'
        return
      }
      if (!this.$refs.socForm.validate()) {
        this.invalidForm = 'Electrical Components'
        return
      }
      if (this.$refs.driverlessForm && !this.$refs.driverlessForm.validate()) {
        this.invalidForm = 'Driverless Components'
        return
      }
      if (!this.$refs.tyreForm.validate()) {
        this.invalidForm = 'Tyre Pressure'
        return
      }
      if (this.$refs.setupForm && !this.$refs.setupForm.validate()) {
        this.invalidForm = 'Setup'
        return
      }
      if (!this.$refs.lapsForm.validate()) {
        this.invalidForm = 'Laps'
        return
      }
      const today = DateTime.now().toISODate()
      const start = DateTime.fromISO(`${today}T${this.local.start}`).toISO()
      const end = DateTime.fromISO(`${today}T${this.local.end}`).toISO()

      const data = {
        driverId: !this.local.driverless ? this.local.driverId : null,
        secondDriverId: !this.local.driverless ? this.local.secondDriverId : null,
        manoeuvreId: this.local.manoeuvreId,
        success: this.local.success,
        driverless: this.local.driverless,
        title: this.local.title,
        notes: this.local.notes,
        start,
        end,
        weatherConditions: this.local.weatherConditions ? this.local.weatherConditions.id : null,
        trackConditions: this.local.trackConditions ? this.local.trackConditions.id : null,
        airTemperature: parseFloat(this.local.airTemperature),
        trackTemperature: parseFloat(this.local.trackTemperature),
        windSpeed: parseFloat(this.local.windSpeed),
        windDirection: this.local.windDirection ? this.local.windDirection.id : null,
        camera: this.local.camera,
        lidar: this.local.lidar,
        motionController: this.local.motionController,
        motionPlanning: this.local.motionPlanning,
        slam: this.local.slam,
        hvSocStart: parseFloat(this.local.hvSocStart),
        hvSocEnd: parseFloat(this.local.hvSocEnd),
        lvSocStart: parseFloat(this.local.lvSocStart),
        lvSocEnd: parseFloat(this.local.lvSocEnd)
      }

      // This will only add wheel-info for warm, warumup and cold tyre pressure if all values are filled in.
      const warmTyrePressure = parseWheels(this.local.warmTyrePressure)
      if (warmTyrePressure) data.warmTyrePressure = warmTyrePressure

      const warmupTyrePressure = parseWheels(this.local.warmupTyrePressure)
      if (warmupTyrePressure) data.warmupTyrePressure = warmupTyrePressure

      const coldTyrePressure = parseWheels(this.local.coldTyrePressure)
      if (coldTyrePressure) data.coldTyrePressure = coldTyrePressure

      data.setup = {}
      data.setup.dvMounted = this.local.setup.dvMounted
      data.setup.tyres = this.local.setup.tyres
      data.setup.tyreAge = this.local.setup.tyreAge
      data.setup.tyreSet = this.local.setup.tyreSet

      const springConfig = parseSpringAxis(this.local.setup.springConfig)
      if (springConfig) data.setup.springConfig = springConfig

      const damperConfig = parseDamperAxis(this.local.setup.damperConfig)
      if (damperConfig) data.setup.damperConfig = damperConfig

      const rideHeightConfig = parseWheels(this.local.setup.rideHeightConfig)
      if (rideHeightConfig) data.setup.rideHeightConfig = rideHeightConfig

      const weightConfig = parseWheels(this.local.setup.weightConfig)
      if (weightConfig) data.setup.weightConfig = weightConfig

      const toeConfig = parseWheels(this.local.setup.toeConfig)
      if (toeConfig) data.setup.toeConfig = toeConfig

      const camberConfig = parseWheels(this.local.setup.camberConfig)
      if (camberConfig) data.setup.camberConfig = camberConfig

      const targetTyrePressure = parseAxis(this.local.setup.targetTyrePressure)
      if (targetTyrePressure) data.setup.targetTyrePressure = targetTyrePressure

      data.laps = this.$refs.lapsForm.getLaps()

      // Goes to either 'views/TestingDay.vue -> methods: createRun' or 'views/TestingRun.vue -> methods: updateRun'
      this.$emit('submit', { data, close })
      return data
    }
  }
}
</script>
