<template>
  <v-dialog v-model="isOpen" :fullscreen="fullscreen">
    <template v-slot:activator="{ on, attrs }">
      <v-btn tile block color="accent" v-bind="attrs" v-on="on"> Shim Calculator </v-btn>
    </template>

    <v-card>
      <v-card-title class="primary"> Shim Calculator </v-card-title>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="6">
            <div class="text-h6">Current state</div>
            <setup-wheel-double-form v-model="current" unit="mm" />
            <v-divider v-if="fullscreen"></v-divider>
          </v-col>

          <v-divider v-if="!fullscreen" vertical></v-divider>

          <v-col cols="12" md="6">
            <div class="text-h6">Calculated Toe</div>
            <setup-wheel-info :config="calculated" unit="°" />
            <v-divider v-if="fullscreen"></v-divider>
          </v-col>

          <v-divider v-if="!fullscreen" vertical></v-divider>

          <v-col cols="12" md="6">
            <div class="text-h6">Target state</div>
            <setup-axis-form v-model="target" unit="°" />
            <v-divider v-if="fullscreen"></v-divider>
          </v-col>

          <v-divider v-if="!fullscreen" vertical></v-divider>

          <v-col cols="12" md="6">
            <div class="text-h6">Shims</div>
            <setup-wheel-info :config="shims" unit="mm" />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="copyCurrent"> Copy current </v-btn>
        <v-btn color="secondary" text @click="copyTargets"> Copy targets </v-btn>
        <v-btn color="primary" text @click="isOpen = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SetupWheelDoubleForm from '@components/setup/WheelDoubleForm.vue'
import SetupWheelInfo from '@components/setup/WheelInfo.vue'
import SetupAxisForm from '@components/setup/AxisForm.vue'

function calcShims(c, t, f) {
  /**
   * c: current
   * t: target
   * f: factor
   */
  const value = (t - c) * f || 0
  const parsedValue = value.toLocaleString('en-GB', { maximumFractionDigits: 2 })
  return value === 0 ? value : value > 0 ? `Add ${parsedValue}` : `Remove ${parsedValue * -1}`
}

/**
 * radians to degrees
 */
function toDeg(radians) {
  return radians * (180 / Math.PI)
}

/**
 * parses all values of an object to float
 */
function objectParseFloat(o) {
  return Object.fromEntries(Object.entries(o).map(([k, v]) => [k, parseFloat(v)]))
}

export default {
  name: 'SetupConfigToeHelper',
  components: { SetupWheelDoubleForm, SetupWheelInfo, SetupAxisForm },
  props: { frontShimFactor: Number, rearShimFactor: Number, wheelbase: Number },
  data: () => ({
    current: {
      frontLeftFront: undefined,
      frontLeftRear: undefined,
      frontRightFront: undefined,
      frontRightRear: undefined,
      rearLeftFront: undefined,
      rearLeftRear: undefined,
      rearRightFront: undefined,
      rearRightRear: undefined
    },
    target: {
      front: 0,
      rear: 0
    },
    isOpen: false
  }),
  methods: {
    copyCurrent() {
      const calculated = objectParseFloat(this.calculated)
      this.$emit('copy', {
        frontLeft: calculated.frontLeft.toFixed(2),
        frontRight: calculated.frontRight.toFixed(2),
        rearLeft: calculated.rearLeft.toFixed(2),
        rearRight: calculated.rearRight.toFixed(2)
      })
      this.isOpen = false
    },
    copyTargets() {
      const target = objectParseFloat(this.target)
      this.$emit('copy', {
        frontLeft: target.front.toFixed(2),
        frontRight: target.front.toFixed(2),
        rearLeft: target.rear.toFixed(2),
        rearRight: target.rear.toFixed(2)
      })
      this.isOpen = false
    },
    open() {
      this.isOpen = true
    }
  },
  computed: {
    shims() {
      const front_factor = this.frontShimFactor
      const rear_factor = this.rearShimFactor
      const calculated = objectParseFloat(this.calculated)
      const target = objectParseFloat(this.target)
      return {
        frontLeft: calcShims(calculated.frontLeft, target.front, front_factor),
        frontRight: calcShims(calculated.frontRight, target.front, front_factor),
        rearLeft: calcShims(calculated.rearLeft, target.rear, rear_factor),
        rearRight: calcShims(calculated.rearRight, target.rear, rear_factor)
      }
    },
    calculated() {
      const hypotenuse = 490 // Breite vom Setup Rad, eig. 495
      const current = objectParseFloat(this.current)
      const wheelcenter = {
        frontLeft: (current.frontLeftFront + current.frontLeftRear) / 2,
        frontRight: (current.frontRightFront + current.frontRightRear) / 2,
        rearLeft: (current.rearLeftFront + current.rearLeftRear) / 2,
        rearRight: (current.rearRightFront + current.rearRightRear) / 2
      }
      const toebarAngle = {
        left: toDeg(Math.asin((wheelcenter.rearLeft - wheelcenter.frontLeft) / this.wheelbase)),
        right: toDeg(Math.asin((wheelcenter.rearRight - wheelcenter.frontRight) / this.wheelbase))
      }
      return {
        frontLeft: toDeg(Math.asin((current.frontLeftFront - current.frontLeftRear) / hypotenuse)) + toebarAngle.left,
        frontRight:
          toDeg(Math.asin((current.frontRightFront - current.frontRightRear) / hypotenuse)) + toebarAngle.right,
        rearLeft: toDeg(Math.asin((current.rearLeftFront - current.rearLeftRear) / hypotenuse)) + toebarAngle.left,
        rearRight: toDeg(Math.asin((current.rearRightFront - current.rearRightRear) / hypotenuse)) + toebarAngle.right
      }
    },
    fullscreen() {
      return ['xs', 'sm', 'md'].includes(this.$vuetify.breakpoint.name)
    }
  }
}
</script>
